<template>
    <v-container fluid style="padding-top: 70px; justify-content: center">
        <alert ref="alertComponent"></alert>
        <v-row align="center" justify="space-around">
            <v-btn tile outlined color="success" @click="loadPipelines">
                <v-icon left>mdi-cached</v-icon>
                Refresh
            </v-btn>
            <v-progress-linear v-if="loading" indeterminate color="cyan"></v-progress-linear>
        </v-row>

        <br/>

        <v-card>
            <!--search input-->
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="pipelines" :search="search" disable-pagination hide-default-footer>
                <!-- pipeline video -->
                <template v-slot:[`item.video`]="{ item }">
                    {{ item.video.playlist && item.video.playlist.type === 'SERIES' ? item.video.playlist.titleVO + ' : ' + item.video.title : item.video.title }}
                </template>

                <!-- pipeline state -->
                <template v-slot:[`item.state`]="{ item }">
                    <v-btn :color="getColorForState(item.state)" class="mr-4">{{ item.state }}</v-btn>
                </template>

                <!-- pipeline duration -->
                <template v-slot:[`item.duration`]="{ item }">
                    {{ durationString(item) }}
                </template>

                <!--update button-->
                <template v-slot:[`item.details`]="{ item }">
                    <router-link :to="{ name: 'Pipeline', params: { id: item.id }}">
                        <v-btn class="mx-2" icon color="#fbc02d">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Alert from "@/components/Alert.component";
import Services from "@/services/api";

export default {
    name: "Pipelines",
    components: {Alert},
    data: () => ({
        search: '',
        headers: [
            {text: 'Video', value: 'video', sortable: true},
            {text: 'User', value: 'user', sortable: true},
            {text: 'State', value: 'state', sortable: true},
            {text: 'Jobs', value: 'jobs.length', sortable: true},
            {text: 'Duration', value: 'duration', sortable: true},
            {text: '', value: 'details', sortable: false},
        ],
        pipelines: [],
        loading: false
    }),
    mounted() {
        this.loadPipelines();
    },
    methods: {
        durationString(item) {
            const duration = item.duration;

            if(item.state === 'RUNNING') {
                return 'Started at ' + item.beginTime;
            }

            if(duration === null || duration === 0) {
                return '-';
            }

            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration - hours * 3600) / 60);
            const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
            const hoursString = hours > 0 ? hours + 'h ' : '';
            const minutesString = minutes > 0 ? minutes + 'min ' : '';
            const secondsString = seconds > 0 ? seconds + 'sec' : '';
            return hoursString + minutesString + secondsString;
        },
        getColorForState(state) {
            if(state === 'RUNNING') return 'blue darken-3';
            if(state === 'FINISHED') return 'success';
            if(state === 'FAILED') return 'error';
            if(state === 'CANCELLED') return 'purple';
            return 'warning';
        },
        loadPipelines() {
            this.loading = true;
            Services.Pipelines.getAllPipelines()
                .then(data => this.pipelines = data)
                .catch(e => this.$refs.alertComponent.showErrorMessage(e.message))
                .finally(() => this.loading = false);
        }
    }
}
</script>
